<template>
	<main>
		<html-load :url="'/icon/index.html'"></html-load>
	</main>
</template>

<script>
export default {
	name: "icon"
}
</script>

<style scoped>

</style>